import React from "react";
import DatePicker, { DateObject } from "react-multi-date-picker";
// import InputIcon from "react-multi-date-picker/components/input_icon";
// import TimePicker from "react-multi-date-picker/plugins/time_picker";

const Playground = () => {
  return (
    <div className="min-h-screen bg-slate-600">
      <div className="relative z-0 w-full py-5 group">
        <DatePicker
          inputClass="block py-2.5 px-0 w-full text-gray-300 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-gray-100 peer"
          id="Date"
          minDate={new DateObject()}
          numberOfMonths={2}
          format="MM/DD/YYYY"
          placeholder="Date / Time"
          // plugins={[<TimePicker position="bottom" hideSeconds />]}
          range
          portal
        />
        <label
          className="peer-focus:font-medium absolute left-0 top-7 text-gray-300 duration-300 transform -translate-y-6 scale-75 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-gray-100 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          htmlFor="Date"
        >
          Return Date
        </label>
      </div>
    </div>
  );
};

export default Playground;
