import React from "react";
import FilterPricesRadio from "./filterPricesRadio";
import useFetch from "../../utils/useFetch";
import { API_URL } from "../../constants";

const FilterPrices = ({ setFilteredData, pricesData }) => {
  // console.log(pricesData);
  // feth data from api for filtering
  const { data: sectors } = useFetch(`${API_URL}charter-sectors/`);
  const { data: airlines } = useFetch(`${API_URL}airlines/`);
  const { data: aircraftTypes } = useFetch(`${API_URL}aircraft-types/`);

  // for charter type filter
  const CharterType = ["Passenger", "Cargo"];
  const handleTypeFilter = (event) => {
    const value = event.target.value;

    const filtered = pricesData.filter((price) =>
      price.charter_type.includes(value)
    );

    setFilteredData(filtered);
  };

  // for Sector filter
  let Sector = [];
  if (sectors !== null) {
    Sector = sectors.map((item) => item.name);
  }
  const handleSectorFilter = (event) => {
    const value = event.target.value;
    const filtered = pricesData.filter((price) =>
      price.charter_sector.name.includes(value)
    );

    setFilteredData(filtered);
  };

  // for Airlines filter
  let Airlines = [];
  if (airlines !== null) {
    Airlines = airlines.map((item) => item.name);
  }
  const handleAirlinesFilter = (event) => {
    const value = event.target.value;
    const filtered = pricesData.filter((price) =>
      price.airline.name.includes(value)
    );

    setFilteredData(filtered);
  };

  // for aircraft_type filter
  let AircraftType = [];
  if (aircraftTypes !== null) {
    AircraftType = aircraftTypes.map((item) => `${item.code}: ${item.name}`);
  }
  const handleAircraftTypeFilter = (event) => {
    const value = event.target.value;
    const filtered = pricesData.filter((price) =>
      price.aircraft_type.code.includes(value.split(":")[0])
    );

    setFilteredData(filtered);
  };

  return (
    <div className="lg:columns-1 sm:columns-2 gap-20">
      <div className="flex items-center mb-5">
        <input
          id="All"
          type="radio"
          onChange={handleTypeFilter}
          value=""
          name="filter"
          className="w-4 h-4 text-gray-300 bg-gray-100 border-gray-300 focus:ring-gray-300"
          defaultChecked={true}
        />
        <label htmlFor="All" className="ms-2 text-sm font-medium text-gray-300">
          All
        </label>
      </div>
      {/* Charter Type */}
      <div className="break-inside-avoid">
        <h1 className="text-lg border-b border-[#9E9E9E] mb-4 pb-2">
          Charter Type
        </h1>
        <FilterPricesRadio
          handleTypeFilter={handleTypeFilter}
          filtering={CharterType}
        />
      </div>
      {/* Sector */}
      <div className="break-inside-avoid">
        <h1 className="text-lg border-b border-[#9E9E9E] my-4 py-2">Sector</h1>
        <FilterPricesRadio
          handleTypeFilter={handleSectorFilter}
          filtering={Sector}
        />
      </div>
      {/* Airlines */}
      <div className="break-inside-avoid">
        <h1 className="text-lg border-b border-[#9E9E9E] my-4 py-2">
          Airlines
        </h1>
        <FilterPricesRadio
          handleTypeFilter={handleAirlinesFilter}
          filtering={Airlines}
        />
      </div>
      {/* AircraftType */}
      <div className="break-inside-avoid">
        <h1 className="text-lg border-b border-[#9E9E9E] my-4 py-2">
          Aircraft Type
        </h1>
        <FilterPricesRadio
          handleTypeFilter={handleAircraftTypeFilter}
          filtering={AircraftType}
        />
      </div>
    </div>
  );
};

export default FilterPrices;
