import React from "react";
import Slider from "react-slick";
import useFetch from "../../utils/useFetch";
import { API_URL } from "../../constants";

const Banner = () => {
  // fetching data
  const { data: banners, error } = useFetch(`${API_URL}promotional-banners/`);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    // autoplay: true,
  };

  return (
    <div className="banner-slider relative rounded-lg pt-10 lg:px-32 px-5">
      {banners ? (
        <Slider {...settings}>
          {banners.map((banner, index) => (
            <div className="p-1" key={index}>
              <div className="max-w-full bg-[#ECF0F7] border-[1px] border-[#CCCCCC] rounded-lg shadow">
                <img
                  className="rounded-lg w-full max-h-[500px] object-cover"
                  src={banner.image}
                  alt={banner.id}
                />
                {banner.description && (
                  <div className="p-10">
                    <p className="mb-3">{banner.description}</p>
                  </div>
                )}
              </div>
            </div>
          ))}
        </Slider>
      ) : error ? (
        <p className="text-center">{error}</p>
      ) : (
        <p className="text-center">loading</p>
      )}
    </div>
  );
};

export default Banner;
