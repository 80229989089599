import React from "react";
import { API_URL } from "../../constants";
import useFetch from "../../utils/useFetch";

const About = ({ aboutRef }) => {
  // fetching data
  const { data: about, error: about_error } = useFetch(`${API_URL}home/`);

  return (
    <div ref={aboutRef}>
      {about ? (
        <div className="md:flex flex-row md:py-36 py-20">
          <div className="basis-3/5 md:pl-24 px-5 pl-4">
            <h1
              className="font-light md:text-6xl text-4xl pb-6"
              style={{ color: "#547196" }}
            >
              About <b className="font-bold">Chariot Charters</b>
            </h1>
            <p className="text-[#7A7A7A]">{about[0].description}</p>
          </div>
          <div className="basis-2/5 self-center">
            <img
              className="block mx-auto w-4/5 my-4 "
              alt="plane"
              src={require("../../assets/images/vector-plane.png")}
            />
          </div>
        </div>
      ) : about_error ? (
        <p className="text-center">{about_error}</p>
      ) : (
        <p className="text-center">loading</p>
      )}
    </div>
  );
};

export default About;
