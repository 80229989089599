import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { useRef } from "react";

import Home from "../pages/home";

// for testing
import Playground from "../pages/playground/playground";

import ToTop from "./scrollToTop";
import Nav from "../components/general/nav";

import Prices from "../pages/prices";
import VendorForm from "../pages/vendorForm";
import NotFound from "../pages/notFound";

const Routing = () => {
  const aboutRef = useRef(null);
  const serviceRef = useRef(null);
  const partnersRef = useRef(null);
  const enquiryRef = useRef(null);

  const MainLayout = ({ children }) => {
    return (
      <>
        <Nav
          serviceRef={serviceRef}
          aboutRef={aboutRef}
          partnersRef={partnersRef}
          enquiryRef={enquiryRef}
        />
        <ToTop />
        {children}
      </>
    );
  };
  return (
    <Router>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <MainLayout>
              <Home
                serviceRef={serviceRef}
                aboutRef={aboutRef}
                partnersRef={partnersRef}
                enquiryRef={enquiryRef}
              />
            </MainLayout>
          }
        />
        <Route
          exact
          path="/form"
          element={
            <MainLayout>
              <VendorForm />
            </MainLayout>
          }
        />
        <Route
          exact
          path="/prices"
          element={
            <MainLayout>
              <Prices />
            </MainLayout>
          }
        />
        <Route exact path="/playground" element={<Playground />} />

        {/* default routes for invalid links */}
        <Route
          path="*"
          element={
            <MainLayout>
              <NotFound />
            </MainLayout>
          }
        />
      </Routes>
    </Router>
  );
};

export default Routing;
