import React from "react";
import useFetch from "../../utils/useFetch";

const UserInfo = ({ prevStep, nextStep, handleChange, values }) => {
  const Continue = (event) => {
    event.preventDefault();
    nextStep();
  };

  const Previous = (event) => {
    event.preventDefault();
    prevStep();
  };

  const { data: countries, error } = useFetch(
    `https://restcountries.com/v3.1/all`
  );

  return (
    <form action="" onSubmit={Continue}>
      <div className="md:grid grid-cols-2 gap-x-5">
        <div className="relative z-0 w-full my-5 group">
          <input
            id="name"
            type="text"
            placeholder=" "
            onChange={handleChange("name")}
            defaultValue={values.name}
            className="block py-2.5 px-0 w-full text-gray-300 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-gray-100 peer"
            required
          />
          <label
            htmlFor="name"
            className="peer-focus:font-medium absolute text-gray-300 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-gray-100 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Name <span className="text-red-600">*</span>
          </label>
        </div>
        <div className="relative z-0 w-full my-5 group">
          <input
            id="email"
            type="email"
            placeholder=" "
            onChange={handleChange("email")}
            defaultValue={values.email}
            className="block py-2.5 px-0 w-full text-gray-300 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-gray-100 peer"
            required
          />
          <label
            htmlFor="email"
            className="peer-focus:font-medium absolute text-gray-300 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-gray-100 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Email <span className="text-red-600">*</span>
          </label>
        </div>
        <div className="relative z-0 w-full my-5 group">
          <input
            id="company"
            type="text"
            placeholder=" "
            onChange={handleChange("company")}
            defaultValue={values.company}
            className="block py-2.5 px-0 w-full text-gray-300 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-gray-100 peer"
            required
          />
          <label
            htmlFor="company"
            className="peer-focus:font-medium absolute text-gray-300 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-gray-100 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Company <span className="text-red-600">*</span>
          </label>
        </div>
        <div className="relative z-0 w-full my-5 group">
          <label
            htmlFor="country"
            className="peer-focus:font-medium absolute text-gray-300 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-gray-100 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Country <span className="text-red-600">*</span>
          </label>
          <input
            className="block py-2.5 px-0 w-full text-gray-300 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-gray-100"
            onChange={handleChange("country")}
            defaultValue={values.country}
            list="countryData"
            placeholder="Country*"
            name="country"
            id="country"
            required
          />
          <datalist id="countryData">
            {countries ? (
              <>
                {countries.map((item, index) => (
                  <option
                    key={index}
                    className="text-black"
                    value={item.name.common}
                  />
                ))}
              </>
            ) : error ? (
              <p className="text-center">{error}</p>
            ) : (
              <p className="text-center">loading</p>
            )}
          </datalist>
        </div>
        <div className="relative z-0 w-full my-5 group">
          <input
            id="telephone"
            type="number"
            placeholder=" "
            onChange={handleChange("telephone")}
            defaultValue={values.telephone}
            className="block py-2.5 px-0 w-full text-gray-300 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-gray-100 peer"
            required
          />
          <label
            htmlFor="telephone"
            className="peer-focus:font-medium absolute text-gray-300 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-gray-100 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Telephone <span className="text-red-600">*</span>
          </label>
        </div>
        <div className="relative z-0 w-full my-5 group">
          <input
            id="mobile"
            type="number"
            placeholder=" "
            onChange={handleChange("mobile")}
            defaultValue={values.mobile}
            className="block py-2.5 px-0 w-full text-gray-300 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-gray-100 peer"
          />
          <label
            htmlFor="mobile"
            className="peer-focus:font-medium absolute text-gray-300 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-gray-100 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Mobile
          </label>
        </div>
      </div>

      <button className="mr-4" onClick={Previous} type="button">
        Back
      </button>
      <button
        // onClick={Continue}
        type="submit"
      >
        Next
      </button>
    </form>
  );
};

export default UserInfo;
