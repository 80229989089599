import React from "react";
import useFetchAirport from "../../utils/useFetchAirport";
// import DatePicker from "react-multi-date-picker";

const PlaneInfo = ({ nextStep, handleChange, values, isPassenger }) => {
  const { data: airportsFrom } = useFetchAirport(values.depFrom);
  const { data: airportsTo } = useFetchAirport(values.destination);

  const Continue = (event) => {
    event.preventDefault();
    nextStep();
  };

  const AirportOption = ({ index, airport }) => {
    let airportName = `${airport.name} (${airport.iata_code})`;
    let airportOptionLabelStrings = [
      airportName,
      airport.municipality,
      airport.region.name,
      airport.country.name,
    ];
    airportOptionLabelStrings = airportOptionLabelStrings.filter(
      (item) => item !== null && item !== "" && item !== undefined
    );
    return (
      <option
        key={index}
        className="text-black"
        label={airportOptionLabelStrings.join(", ")}
        value={airportName}
      />
    );
  };

  return (
    <form action="" onSubmit={Continue} className="text-lg">
      {isPassenger && (
        <div className="relative z-0 w-full my-5 group">
          <input
            id="noOfPax"
            type="number"
            min={1}
            placeholder=" "
            onChange={handleChange("noOfPax")}
            defaultValue={values.noOfPax}
            className="block py-2.5 px-0 w-full text-gray-300 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-gray-100 peer"
            required
          />
          <label
            className="peer-focus:font-medium absolute text-gray-300 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-gray-100 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            htmlFor="noOfPax"
          >
            No. of Passengers <span className="text-red-600">*</span>
          </label>
        </div>
      )}

      <div className={`grid lg:grid-cols-2 gap-x-5`}>
        {/* From City */}
        <div>
          <div className="relative z-0 w-full my-5 group">
            <label
              htmlFor="depAirport"
              className="peer-focus:font-medium absolute text-gray-300 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-gray-100 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Departing Airport <span className="text-red-600">*</span>
            </label>
            <input
              className="block py-2.5 px-0 w-full text-gray-300 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-gray-100 h-[52px] peer"
              onChange={handleChange("depFrom")}
              defaultValue={values.depFrom}
              list="depAirportData"
              placeholder="Departing Airport *"
              name="depAirport"
              id="depAirport"
              required
            />
            <datalist id="depAirportData">
              {airportsFrom.map((item, index) => (
                <AirportOption key={index} airport={item} />
              ))}
            </datalist>
          </div>
          <div className="md:grid grid-cols-2 gap-x-5">
            <div className="relative z-0 w-full my-5 group">
              <input
                className="block py-2.5 px-0 w-full text-gray-300 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-gray-100 peer"
                style={{ colorScheme: "dark" }}
                id="depDate"
                type="date"
                min={new Date().toJSON().slice(0, 10)}
                max={values.returnDate}
                placeholder=" "
                required
                onChange={handleChange("depDate")}
                defaultValue={values.depDate}
              />
              <label
                className="peer-focus:font-medium absolute text-gray-300 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-gray-100 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                htmlFor="depDate"
              >
                Departure Date <span className="text-red-600">*</span>
              </label>
            </div>
            <div className="relative z-0 w-full my-5 group">
              <input
                className="block py-2.5 px-0 w-full text-gray-300 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-gray-100 peer"
                style={{ colorScheme: "dark" }}
                id="depTime"
                type="time"
                placeholder=" "
                onChange={handleChange("depTime")}
                defaultValue={values.depTime}
              />
              <label
                className="peer-focus:font-medium absolute text-gray-300 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-gray-100 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                htmlFor="depTime"
              >
                Departure Time
              </label>
            </div>
          </div>
        </div>

        {/* To City */}
        <div>
          <div className="relative z-0 w-full my-5 group">
            <label
              htmlFor="destination"
              className="peer-focus:font-medium absolute text-gray-300 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-gray-100 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Destination Airport <span className="text-red-600">*</span>
            </label>
            <input
              className="block py-2.5 px-0 w-full text-gray-300 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-gray-100 h-[52px] peer"
              onChange={handleChange("destination")}
              defaultValue={values.destination}
              list="destinationData"
              placeholder="Destination Airport *"
              name="destination"
              id="destination"
              required
            />
            <datalist id="destinationData">
              {airportsTo.map((item, index) => (
                <AirportOption key={index} airport={item} />
              ))}
            </datalist>
          </div>
          {/* {isPassenger && ( */}
          <div className="md:grid grid-cols-2 gap-x-5">
            <div className="relative z-0 w-full my-5 group">
              {/* <DatePicker
                  inputClass="block py-2.5 px-0 w-full text-gray-300 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-gray-100"
                  style={{ colorScheme: "dark" }}
                  id="returnDate"
                  // type="date"
                  minDate={
                    values.depDate
                      ? values.depDate
                      : new Date().toJSON().slice(0, 10)
                  }
                  placeholder="DD/MM/YYYY"
                  format="DD/MM/YYYY"
                  // showOtherDays="true"
                  portal
                  onChange={handleChange("returnDate")}
                  defaultValue={values.returnDate}
                /> */}
              <input
                className="block py-2.5 px-0 w-full text-gray-300 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-gray-100"
                style={{ colorScheme: "dark" }}
                id="returnDate"
                type="date"
                min={
                  values.depDate
                    ? values.depDate
                    : new Date().toJSON().slice(0, 10)
                }
                placeholder=" "
                onChange={handleChange("returnDate")}
                defaultValue={values.returnDate}
              />
              <label
                className="peer-focus:font-medium absolute left-0 text-gray-300 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-gray-100 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                htmlFor="returnDate"
              >
                Return Date
              </label>
            </div>
            <div className="relative z-0 w-full my-5 group">
              <input
                className="block py-2.5 px-0 w-full text-gray-300 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-gray-100 peer"
                style={{ colorScheme: "dark" }}
                id="returnTime"
                type="time"
                placeholder=" "
                onChange={handleChange("returnTime")}
                defaultValue={values.returnTime}
              />
              <label
                className="peer-focus:font-medium absolute text-gray-300 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-gray-100 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                htmlFor="returnTime"
              >
                Return Time
              </label>
            </div>
          </div>
          {/* )} */}
        </div>
      </div>

      {/* Additional Info */}
      {/* <div className="p-2 border border-white rounded-xl"> */}
      {!isPassenger && (
        <div className="grid 2xl:grid-cols-4 md:grid-cols-2 gap-x-5">
          <div className="relative z-0 w-full my-5 group">
            <input
              id="totalWeight"
              type="number"
              min={0}
              placeholder=" "
              onChange={handleChange("totalWeight")}
              defaultValue={values.totalWeight}
              className="block py-2.5 px-0 w-full text-gray-300 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-gray-100 peer"
            />
            <label
              htmlFor="totalWeight"
              className="whitespace-nowrap peer-focus:font-medium absolute text-gray-300 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-gray-100 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Total weight (tons)
            </label>
          </div>
          <div className="relative z-0 w-full my-5 group">
            <input
              id="totalVol"
              type="number"
              min={0}
              placeholder=" "
              onChange={handleChange("totalVol")}
              defaultValue={values.totalVol}
              className="block py-2.5 px-0 w-full text-gray-300 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-gray-100 peer"
            />
            <label
              htmlFor="totalVol"
              className="whitespace-nowrap peer-focus:font-medium absolute text-gray-300 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-gray-100 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Total volume (m3)
            </label>
          </div>
          <div className="relative z-0 w-full my-5 group">
            <input
              id="largestSize"
              type="number"
              min={0}
              placeholder=" "
              onChange={handleChange("largestSize")}
              defaultValue={values.largestSize}
              className="block py-2.5 px-0 w-full text-gray-300 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-gray-100 peer"
            />
            <label
              htmlFor="largestSize"
              className="whitespace-nowrap peer-focus:font-medium absolute text-gray-300 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-gray-100 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Weight of Largest Piece (kg)
            </label>
          </div>
          <div className="relative z-0 w-full my-5 group">
            <input
              id="dimensions"
              type="number"
              min={0}
              placeholder=" "
              onChange={handleChange("dimensions")}
              defaultValue={values.dimensions}
              className="block py-2.5 px-0 w-full text-gray-300 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-gray-100 peer"
            />
            <label
              htmlFor="dimensions"
              className="whitespace-nowrap peer-focus:font-medium absolute text-gray-300 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-gray-100 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Largest Dimensions (cm)
            </label>
          </div>
          <div className="relative z-0 w-full my-5 group 2xl:col-span-4 md:col-span-2">
            <textarea
              id="commodityDetails"
              rows="1"
              type="text"
              placeholder=" "
              onChange={handleChange("commodityDetails")}
              defaultValue={values.commodityDetails}
              className="block py-2.5 px-0 w-full text-gray-300 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-gray-100 peer"
            />
            <label
              htmlFor="commodityDetails"
              className="peer-focus:font-medium absolute text-gray-300 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-gray-100 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Commodity Details
            </label>
          </div>
        </div>
      )}
      <div className="relative z-0 w-full my-5 group">
        <textarea
          id="addInfo"
          rows="4"
          type="text"
          placeholder=" "
          onChange={handleChange("addInfo")}
          defaultValue={values.addInfo}
          className="block py-2.5 px-0 w-full text-gray-300 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-gray-100 peer"
        />
        <label
          htmlFor="addInfo"
          className="peer-focus:font-medium absolute text-gray-300 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-gray-100 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
        >
          Additional Information
        </label>
      </div>
      {/* </div> */}

      <div className="flex items-center justify-between">
        <button
          // onClick={Continue}
          type="submit"
        >
          Next
        </button>
      </div>
    </form>
  );
};

export default PlaneInfo;
