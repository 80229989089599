import React, { useState } from "react";
import EnquiryForm from "./enquiryForm";

const Enquiry = ({ enquiryRef }) => {
  const [isPassenger, setisPassenger] = useState(true);
  const [step, setStep] = useState(1);

  const handleClick = (val) => {
    setisPassenger(val);
    setStep(1);
  };

  return (
    <div
      ref={enquiryRef}
      className="bg-[#334A6D] z-10 relative md:min-h-[80vh]"
    >
      <div className="xl:w-[70vw] md:p-24 px-5 py-10">
        <h1 className="text-white md:text-5xl text-3xl mb-7 md:pt-5">
          Enquiry Form
        </h1>
        <div className="grid grid-cols-2 gap-4">
          <button
            className={isPassenger ? "bg-[#ECF0F7] text-black" : ""}
            onClick={() => {
              handleClick(true);
            }}
          >
            Passenger
          </button>
          <button
            className={!isPassenger ? "bg-[#ECF0F7] text-black" : ""}
            onClick={() => {
              handleClick(false);
            }}
          >
            Cargo
          </button>
        </div>
        <div className="pt-5">
          <EnquiryForm
            isPassenger={isPassenger}
            setStep={setStep}
            step={step}
          />
        </div>
        {/* <EnquiryForm isPassenger={isPassenger} /> */}
      </div>
      <img
        className="absolute bottom-0 right-0 lg:w-[35vw] xl:block hidden object-contain -z-10"
        src={require("../../assets/images/world.png")}
        alt="plane world"
      />
    </div>
  );
};

export default Enquiry;
