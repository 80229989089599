import { useState, useEffect } from "react";
import { API_URL } from "../constants";

const useFetchAirport = (searchKey) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  // console.log("Updating Airports");

  useEffect(() => {
    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    headers.append(
      "Authorization",
      "Basic " + window.btoa("frontend:272G:S4QvS!eSgJ")
    );
    fetch(`${API_URL}airports/?search=${searchKey}`)
      .then((res) => {
        if (!res.ok) {
          throw Error("Can not fetch data from that resource");
        }
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        setData(data.results);
      })
      .catch((err) => {
        setError(err.message);
      });
  }, [searchKey]);

  return { data, error };
};

export default useFetchAirport;
