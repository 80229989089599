import React from "react";
import VendorInformation from "../components/vendor/vendorInformation";
import Additional from "../components/vendor/additional";
import Footer from "../components/general/footer";
import { Form, Formik } from "formik";
import { API_URL } from "../constants";
import axios from "axios";
import { ToastNotification } from "../utils/toastNotification";

const VendorForm = () => {
  const initialValues = {
    companyName: "",
    incharge: "",
    contact: "",
    email: "",
    baseAirport: "",
    passengerCharter: [],
    cargoCharter: [],
    addInfo: "",
  };

  const reset = (data) => {
    data.companyName = "";
    data.incharge = "";
    data.contact = "";
    data.email = "";
    data.baseAirport = "";
    data.passengerCharter = [];
    data.cargoCharter = [];
    data.addInfo = "";
  };

  const Submit = (data) => {
    // passenger charter data
    const passCharter = data.passengerCharter;
    const passCharterData = passCharter.map((pass) => ({
      proposed_charter_sector: pass.charterSector,
      proposed_aircraft_type: pass.aircraftType,
      aircraft_manufacture_date: pass.aircraftMFD,
      no_of_aircraft: pass.aircraftNumber,
      first_class_seats: pass.firstSeats,
      business_class_seats: pass.businessSeats,
      economy_class_seats: pass.economySeats,
      rate_per_hour: pass.ratePerHour,
      rate_for_one_way: pass.rateOneWay,
      rate_for_return: pass.rateReturn,
      rate_validity_start: pass.rateValidityStart,
      rate_validity_end: pass.rateValidityEnd,
    }));

    // cargo charter data
    const cargoCharter = data.cargoCharter;
    const cargoCharterData = cargoCharter.map((cargo) => ({
      proposed_charter_sector: cargo.charterSector,
      proposed_aircraft_type: cargo.aircraftType,
      no_of_aircraft: cargo.aircraftNumber,
      cargo_capacity: cargo.cargoCapacity,
      cargo_dimensions: [
        {
          length: cargo.cargo_dimensions[0].maxLength,
          width: cargo.cargo_dimensions[0].maxWidth,
          height: cargo.cargo_dimensions[0].maxHeight,
          pieces: cargo.cargo_dimensions[0].pieces,
        },
      ],
      rate_per_hour: cargo.ratePerHour,
      rate_for_one_way: cargo.rateOneWay,
      rate_for_return: cargo.rateReturn,
      rate_validity_start: cargo.rateValidityStart,
      rate_validity_end: cargo.rateValidityEnd,
    }));

    // data to send
    let sendData = JSON.stringify(
      {
        company_name: data.companyName,
        person_incharge: data.incharge,
        contact_number: data.contact,
        email: data.email,
        base_airport: data.baseAirport,
        passenger_charter_rates: passCharterData,
        cargo_charter_rates: cargoCharterData,
        additional_information: data.addInfo,
      },
      null,
      2
    );

    // console.log(`data being sent: ${sendData}`);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${API_URL}vendor-registration/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${window.btoa("frontend:272G:S4QvS!eSgJ")}`,
      },
      data: sendData,
    };
    axios
      .request(config)
      .then((response) => {
        ToastNotification.fire({
          icon: "success",
          title: "Successful!",
        });
      })
      .catch((error) => {
        console.log(error);
        ToastNotification.fire({
          icon: "warning",
          title: "Failed!",
        });
      });

    reset(data);
  };

  return (
    <div className="pt-[107px]">
      <div
        className="text-center text-4xl pt-10 font-bold pb-6"
        style={{ color: "#334A6D" }}
      >
        Vendor Registration
      </div>

      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          await new Promise((r) => setTimeout(r, 500));
          let data = values;
          Submit(data);
          // console.log(data);
        }}
      >
        {({ values }) => (
          <Form className="max-w-screen-2xl mx-auto px-5 pb-20">
            <VendorInformation values={values} />
            <Additional values={values} />
            {values.passengerCharter.length > 0 ||
            values.cargoCharter.length > 0 ? (
              <button
                className="font-normal bg-transparent text-[#334A6D] py-2 px-4  border-2 border-[#334A6D] hover:text-white hover:bg-[#334A6D]"
                type="submit"
              >
                Submit
              </button>
            ) : (
              <>
                <p className="text-[15px] text-red-500 pb-2">
                  Add Passenger Charter or Cargo Charter to Submit.
                </p>
                <button className="font-normal bg-transparent text-gray-300 py-2 px-4 border-2 border-gray-300 hover:text-gray-300">
                  Submit
                </button>
              </>
            )}
          </Form>
        )}
      </Formik>
      <Footer />
    </div>
  );
};

export default VendorForm;
