import React, { useContext, useEffect, useState } from "react";
import { MenuContext } from "react-flexible-sliding-menu";
import { AiOutlineMenu } from "react-icons/ai";
import { FiPhone } from "react-icons/fi";
import { GrLocation } from "react-icons/gr";
import { IoMailOutline } from "react-icons/io5";
import useFetch from "../../utils/useFetch";
import { API_URL } from "../../constants";
import { useLocation } from "react-router-dom";

const Nav = ({ serviceRef, aboutRef, partnersRef, enquiryRef }) => {
  const { data, error } = useFetch(`${API_URL}home/`);
  const [color, setColor] = useState(false);

  const { pathname } = useLocation();

  const changeColor = () => {
    if (window.scrollY <= 50 && pathname === "/") {
      setColor(false);
    } else {
      setColor(true);
    }
  };

  useEffect(() => {
    if (pathname === "/") {
      setColor(false);
    } else {
      setColor(true);
    }
  }, [pathname]);

  window.addEventListener("scroll", changeColor);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const scrollToAbout = () => {
    aboutRef.current.style.scrollMargin = "100px";
    aboutRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToServices = () => {
    serviceRef.current.style.scrollMargin = "100px";
    serviceRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToPartners = () => {
    partnersRef.current.style.scrollMargin = "200px";
    partnersRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToEnquiry = () => {
    enquiryRef.current.style.scrollMargin = "100px";
    enquiryRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const navItems = [
    {
      label: "Home",
      path: "/",
      scroll: scrollToTop,
    },
    {
      label: "About us",
      path: "/",
      scroll: scrollToAbout,
    },
    {
      label: "Services",
      path: "/",
      scroll: scrollToServices,
    },
    {
      label: "Partners",
      path: "/",
      scroll: scrollToPartners,
    },
    {
      label: "Enquiry",
      path: "/",
      scroll: scrollToEnquiry,
    },
  ];

  const { toggleMenu } = useContext(MenuContext);

  return (
    <div>
      <nav
        className={`fixed top-0 w-full z-50 transition duration-150 ease-in-out ${
          color ? "shadow-lg bg-[#ECF0F7]" : ""
        } ${pathname === "/" ? "" : ""} transition-all ease-in duration-300`}
      >
        {data ? (
          <div
            className={`py-3 text-center lg:block hidden ${
              color
                ? "invisible absolute bg-[#00000000]"
                : "visible bg-[#ecf0f740]"
            }`}
          >
            <a
              className={`mr-6 lg:inline block ${
                pathname === "/" ? "text-white" : ""
              } text-[15px]`}
              href={`tel: ${data[3].description}`}
            >
              <FiPhone className="inline mr-2" />
              {data[3].description}
            </a>

            <a
              className={`mr-6 lg:inline block ${
                pathname === "/" ? "text-white" : ""
              } text-[15px]`}
              href={`mailto: ${data[4].description}`}
            >
              <IoMailOutline className="inline mr-2" />
              {data[4].description}
            </a>

            <a
              className={`lg:inline block ${
                pathname === "/" ? "text-white" : ""
              } text-[15px]`}
              href={data[6].description}
              target="_blank"
              rel="noreferrer"
            >
              <GrLocation className="inline mr-2" />
              {data[5].description}
            </a>
          </div>
        ) : error ? (
          <p className="text-center">{error}</p>
        ) : (
          <p className="text-center">loading</p>
        )}

        <div className="py-5 md:mx-20 mx-0 flex items-center justify-between">
          <a href="/">
            <img
              // onClick={scrollToTop}
              className="cursor-pointer md:w-auto w-[40vw]"
              src={
                color
                  ? require("../../assets/images/logo.png")
                  : require("../../assets/images/logoWhite.png")
              }
              alt="logo"
            />
          </a>
          <div className="space-x-6 lg:block hidden ">
            {pathname === "/" ? (
              <>
                {navItems.map((item, index) => (
                  <p
                    key={index}
                    onClick={item.scroll}
                    className={`cursor-pointer text-[15px] inline ${
                      color ? "text-black" : "text-white"
                    }`}
                  >
                    {item.label}
                  </p>
                ))}
              </>
            ) : (
              <>
                {navItems.map((item, index) => (
                  <a
                    key={index}
                    href={item.path}
                    className={`cursor-pointer text-[15px] inline ${
                      color ? "text-black" : "text-white"
                    }`}
                  >
                    {item.label}
                  </a>
                ))}
              </>
            )}
            <a
              href="/prices"
              className={`cursor-pointer text-[15px] inline ${
                color ? "text-black" : "text-white"
              }`}
            >
              Prices
            </a>
            <a
              href="/form"
              className={`cursor-pointer text-[15px] inline ${
                color ? "text-black" : "text-white"
              }`}
            >
              Vendor Form
            </a>
          </div>

          {/* HAM MENU open full page menu */}
          <span
            className={`toggleMenu lg:hidden ${
              color ? "text-black" : "text-white"
            }`}
            onClick={toggleMenu}
          >
            <AiOutlineMenu />
          </span>
        </div>
      </nav>
    </div>
  );
};

export default Nav;
