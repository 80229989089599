import { Field } from "formik";
import React from "react";

const Additional = ({ values }) => {
  return (
    <div className=" pt-12" style={{ color: "#334A6D" }}>
      <div className="text-2xl pb-2 font-bold mb-8 border-b-2 border-[#334A6D]">
        Additional Remarks
      </div>

      <div className="grid md:gap-6">
        <div className="relative z-0 w-full mb-5 group">
          <Field
            id="addInfo"
            name={`addInfo`}
            component="textarea"
            type="text"
            rows="4"
            className="block py-2.5 w-full bg-transparent border-b focus:outline-none peer"
            style={{ borderBlockColor: "#334A6D" }}
            placeholder=" "
          />
          <label className="absolute text-md duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-placeholder-shown:translate-y-0  peer-focus:scale-75 peer-focus:-translate-y-6 peer-placeholder-shown:scale-100 uppercase">
            Additional Information
          </label>
        </div>
      </div>
    </div>
  );
};

export default Additional;
