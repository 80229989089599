import React, { Component } from "react";
import PlaneInfo from "./planeInfo";
// import AddInfo from "./addInfo";
import UserInfo from "./userInfo";
import Confirmation from "./confirmation";

export default class EnquiryForm extends Component {
  state = {
    step: 1,
    noOfPax: "",
    depFrom: "",
    depDate: "",
    depTime: "",
    destination: "",
    returnDate: null,
    returnTime: null,
    totalWeight: "",
    totalVol: "",
    largestSize: "",
    dimensions: "",
    commodityDetails: "",
    addInfo: "",
    name: "",
    email: "",
    company: "",
    telephone: "",
    country: "",
    mobile: "",
  };

  // go back to first step
  firstStep = () => {
    this.props.setStep(1);
    this.setState({
      // step: 1,
      noOfPax: "",
      depFrom: "",
      depDate: "",
      depTime: "",
      destination: "",
      returnDate: null,
      returnTime: null,
      totalWeight: "",
      totalVol: "",
      largestSize: "",
      dimensions: "",
      commodityDetails: "",
      addInfo: "",
      name: "",
      email: "",
      company: "",
      telephone: "",
      country: "",
      mobile: "",
    });
  };

  // go back to previous step
  prevStep = () => {
    // const { step } = this.state;
    // this.setState({ step: step - 1 });
    this.props.setStep(this.props.step - 1);
  };

  // proceed to the next step
  nextStep = () => {
    // const { step } = this.state;
    // this.setState({ step: step + 1 });
    this.props.setStep(this.props.step + 1);
  };

  // Handle fields change
  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  render() {
    // const { step } = this.state;
    const {
      noOfPax,
      depFrom,
      depDate,
      depTime,
      destination,
      returnDate,
      returnTime,
      totalWeight,
      totalVol,
      largestSize,
      dimensions,
      commodityDetails,
      addInfo,
      name,
      email,
      company,
      telephone,
      country,
      mobile,
    } = this.state;

    const values = {
      noOfPax,
      depFrom,
      depDate,
      depTime,
      destination,
      returnDate,
      returnTime,
      totalWeight,
      totalVol,
      largestSize,
      dimensions,
      commodityDetails,
      addInfo,
      name,
      email,
      company,
      telephone,
      country,
      mobile,
    };

    switch (this.props.step) {
      case 1:
        return (
          <PlaneInfo
            isPassenger={this.props.isPassenger}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
          />
        );
      case 2:
        //     return (
        //       <AddInfo
        //         isPassenger={this.props.isPassenger}
        //         prevStep={this.prevStep}
        //         nextStep={this.nextStep}
        //         handleChange={this.handleChange}
        //         values={values}
        //       />
        //     );
        //   case 3:
        return (
          <UserInfo
            isPassenger={this.props.isPassenger}
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
          />
        );
      case 3:
        return (
          <Confirmation
            isPassenger={this.props.isPassenger}
            prevStep={this.prevStep}
            firstStep={this.firstStep}
            values={values}
          />
        );
      default:
      // do nothing
    }
  }
}
