import React from "react";
import bgImg from "../../assets/images/book-image.png";
import { API_URL } from "../../constants";
import useFetch from "../../utils/useFetch";

const Book = ({ enquiryRef }) => {
  // fetching data
  const { data: book, error: book_error } = useFetch(`${API_URL}home/`);
  const scrollToEnquiry = () => {
    enquiryRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="bg-cover" style={{ backgroundImage: `url(${bgImg})` }}>
      {book ? (
        <div className="text-center py-32 px-5 space-y-6 bg-[#588fddb2]">
          <h1 className="md:text-6xl text-4xl text-white">Book with us</h1>
          <p className="lg:mx-72 md:mx-28 mx-0 text-white">
            {book[2].description}
          </p>
          <button onClick={scrollToEnquiry}>Learn More</button>
        </div>
      ) : book_error ? (
        <p className="text-center">{book_error}</p>
      ) : (
        <p className="text-center">loading</p>
      )}
    </div>
  );
};

export default Book;
