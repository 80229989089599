import { React } from "react";
import { IoIosAdd } from "react-icons/io";
import PassengerInformation from "./passengerInformation";
import CargoInformation from "./cargoInformation";
import { Field, FieldArray } from "formik";

const VendorInformation = ({ values }) => {
  return (
    <div className="" style={{ color: "#334A6D" }}>
      <div className="text-2xl pb-2 font-bold mb-8 border-b-2 border-[#334A6D]">
        Vendor Information
      </div>

      {/* form */}
      <div className="grid md:grid-cols-2 gap-6">
        <div className="relative z-0 w-full group">
          <Field
            id="companyName"
            name={`companyName`}
            type="text"
            className="block py-2.5 w-full bg-transparent border-b focus:outline-none peer"
            style={{ borderBlockColor: "#334A6D" }}
            placeholder=" "
            required
          />
          <label className="absolute text-md  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 peer-placeholder-shown:scale-100">
            COMPANY NAME *
          </label>
        </div>
        <div className="relative z-0 w-full group">
          <Field
            id="incharge"
            name={`incharge`}
            type="text"
            className="block py-2.5 w-full bg-transparent border-b border-[#334A6D] focus:outline-none peer"
            style={{ borderBlockColor: "#334A6D" }}
            placeholder=" "
            required
          />
          <label className="absolute text-md duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 peer-placeholder-shown:scale-100">
            PERSON INCHARGE *
          </label>
        </div>

        <div className="relative z-0 w-full group">
          <Field
            id="contact"
            name={`contact`}
            type="number"
            className="block py-2.5 w-full bg-transparent border-b border-[#334A6D]  focus:outline-none peer"
            style={{ borderBlockColor: "#334A6D" }}
            placeholder=" "
            required
          />
          <label className="absolute text-md  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 peer-placeholder-shown:scale-100">
            CONTACT NO *
          </label>
        </div>
        <div className="relative z-0 w-full group">
          <Field
            id="email"
            name={`email`}
            type="email"
            className="block py-2.5 w-full bg-transparent border-b border-[#334A6D]  focus:outline-none peer"
            style={{ borderBlockColor: "#334A6D" }}
            placeholder=" "
            required
          />
          <label className="absolute text-md duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 peer-placeholder-shown:scale-100">
            EMAIL *
          </label>
        </div>

        <div className="relative z-0 w-full group">
          <Field
            id="baseAirport"
            name={`baseAirport`}
            type="text"
            className="block py-2.5 w-full bg-transparent border-b border-[#334A6D]  focus:outline-none peer"
            style={{ borderBlockColor: "#334A6D" }}
            placeholder=" "
            required
          />
          <label className="absolute text-md duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 peer-placeholder-shown:scale-100">
            BASE AIRPORT&nbsp;
            <span className="uppercase text-[#334a6d88]">
              (Ex.: cgk, sin)
            </span>{" "}
            *
          </label>
        </div>
      </div>

      <FieldArray name="passengerCharter">
        {({ remove, push }) => (
          <>
            <div className="flex gap-4 pt-6">
              <div
                className="flex font-normal rounded-lg cursor-pointer uppercase bg-transparent py-2 gap-2 px-4 border-2 text-[#334A6D] border-[#334A6D] hover:bg-[#334A6D] hover:text-white"
                onClick={() =>
                  push({
                    charterSector: "",
                    aircraftType: "",
                    aircraftMFD: "",
                    aircraftNumber: "",
                    firstSeats: "0",
                    businessSeats: "0",
                    economySeats: "0",
                    rateOneWay: "",
                    rateReturn: "",
                    ratePerHour: "",
                    minHours: "",
                    rateValidityStart: "",
                    rateValidityEnd: "",
                  })
                }
              >
                <IoIosAdd className="text-2xl" /> Passenger Charter
              </div>
            </div>
            {values.passengerCharter.length > 0 &&
              values.passengerCharter.map((passenger, index) => (
                <PassengerInformation
                  key={index}
                  passenger={passenger}
                  index={index}
                  remove={remove}
                />
              ))}
          </>
        )}
      </FieldArray>

      <FieldArray name="cargoCharter">
        {({ remove, push }) => (
          <>
            <div className="flex gap-4 pt-6">
              <div
                className="flex font-normal rounded-lg cursor-pointer uppercase bg-transparent py-2 gap-2 px-4 border-2 text-[#334A6D] border-[#334A6D] hover:bg-[#334A6D] hover:text-white"
                onClick={() =>
                  push({
                    charterSector: "",
                    aircraftType: "",
                    aircraftNumber: "",
                    cargoCapacity: "",
                    cargo_dimensions: [
                      {
                        maxLength: "",
                        maxWidth: "",
                        maxHeight: "",
                        pieces: "",
                      },
                    ],
                    rateOneWay: "",
                    rateReturn: "",
                    ratePerHour: "",
                    minHours: "",
                    rateValidityStart: "",
                    rateValidityEnd: "",
                  })
                }
              >
                <IoIosAdd className="text-2xl" /> Cargo Charter
              </div>
            </div>
            {values.cargoCharter.length > 0 &&
              values.cargoCharter.map((cargo, index) => (
                <CargoInformation
                  key={index}
                  cargo={cargo}
                  index={index}
                  remove={remove}
                />
              ))}
          </>
        )}
      </FieldArray>
    </div>
  );
};

export default VendorInformation;
