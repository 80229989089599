import React, { useContext } from "react";
import { RxCross1 } from "react-icons/rx";
import { MenuContext } from "react-flexible-sliding-menu";
import { FiPhone } from "react-icons/fi";
import { IoMailOutline } from "react-icons/io5";
import { GrLocation } from "react-icons/gr";
import useFetch from "../../../utils/useFetch";
import { API_URL } from "../../../constants";

function HamMenu() {
  const { data, error } = useFetch(`${API_URL}home/`);
  const { closeMenu } = useContext(MenuContext);
  const pathname = window.location.pathname;

  const scrollToTop = () => {
    closeMenu();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const scrollToAbout = () => {
    closeMenu();
    window.scrollTo({
      top: 850,
      left: 0,
      behavior: "smooth",
    });
  };

  const scrollToServices = () => {
    closeMenu();
    window.scrollTo({
      top: 2050,
      left: 0,
      behavior: "smooth",
    });
  };

  const scrollToPartners = () => {
    closeMenu();
    window.scrollTo({
      top: 4000,
      left: 0,
      behavior: "smooth",
    });
  };

  const scrollToEnquiry = () => {
    closeMenu();
    window.scrollTo({
      top: 4600,
      left: 0,
      behavior: "smooth",
    });
  };

  const navItems = [
    {
      label: "Home",
      path: "/",
      scroll: scrollToTop,
    },
    {
      label: "About us",
      path: "/",
      scroll: scrollToAbout,
    },
    {
      label: "Services",
      path: "/",
      scroll: scrollToServices,
    },
    {
      label: "Partners",
      path: "/",
      scroll: scrollToPartners,
    },
    {
      label: "Enquiry",
      path: "/",
      scroll: scrollToEnquiry,
    },
  ];

  return (
    <div className="p-5 bg-[#ECF0F7] min-h-full">
      <img
        onClick={scrollToTop}
        className="cursor-pointer md:w-auto w-[40vw]"
        src={require("../../../assets/images/logo.png")}
        alt="logo"
      />
      <div className="pt-20 px-5">
        {pathname === "/" ? (
          <>
            {navItems.map((item, index) => (
              <p
                key={index}
                onClick={item.scroll}
                className="cursor-pointer text-[30px] pb-5 "
              >
                {item.label}
              </p>
            ))}
          </>
        ) : (
          <>
            {navItems.map((item, index) => (
              <a
                key={index}
                href={item.path}
                className="cursor-pointer text-[30px] pb-5 block"
              >
                {item.label}
              </a>
            ))}
          </>
        )}
        <a href="/prices" className="cursor-pointer text-[30px] pb-5 block">
          Prices
        </a>
        <a href="/form" className="cursor-pointer text-[30px] pb-5 block">
          Vendor Form
        </a>
      </div>

      {data ? (
        <div className="mb-8 px-3 absolute bottom-0">
          <a
            className={`mr-6 mb-3 lg:inline block text-black text-[15px]`}
            href={`tel: ${data[3].description}`}
          >
            <FiPhone className="inline mr-2" />
            {data[3].description}
          </a>

          <a
            className={`mr-6 mb-3 lg:inline block text-black text-[15px]`}
            href={`mailto: ${data[4].description}`}
          >
            <IoMailOutline className="inline mr-2" />
            {data[4].description}
          </a>

          <a
            className={`mb-3 lg:inline block text-black text-[15px]`}
            href={data[6].description}
            target="_blank"
            rel="noreferrer"
          >
            <GrLocation className="inline mr-2" />
            {data[5].description}
          </a>
        </div>
      ) : error ? (
        <p className="text-center">{error}</p>
      ) : (
        <p className="text-center">loading</p>
      )}
      {/* close full page menu */}
      <span className="toggleMenu" onClick={closeMenu}>
        <RxCross1 />
      </span>
    </div>
  );
}

export default HamMenu;
