import React from "react";
import { FaRegCopyright } from "react-icons/fa";

const Footer = () => {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <>
      <div className="bg-[#364253] md:px-24 px-5 py-4">
        <div className="text-center ">
          <p className="text-slate-100 pb-2 ">We Accept</p>
          <img
            className="h-7 block mx-auto"
            src={require("../../assets/images/accept.png")}
            alt=""
          />
        </div>
      </div>
      <div className="bg-[rgb(57,57,57)] md:px-24 px-5 py-3 text-center">
        <p className="inline md:text-[16px] text-[14px] text-white">
          <FaRegCopyright className="inline mr-2" />
          {year} | Chariot Charters | Developed by Octacore Solutions
        </p>
      </div>
    </>
  );
};

export default Footer;
