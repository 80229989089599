import React from "react";
import axios from "axios";
import { API_URL } from "../../constants";

// notification
import { ToastNotification } from "../../utils/toastNotification";

const Confirmation = ({ prevStep, firstStep, values, isPassenger }) => {
  const {
    noOfPax,
    depFrom,
    depDate,
    depTime,
    destination,
    returnDate,
    returnTime,
    totalWeight,
    totalVol,
    largestSize,
    dimensions,
    commodityDetails,
    addInfo,
    name,
    email,
    company,
    telephone,
    country,
    mobile,
  } = values;

  const Submit = (event) => {
    event.preventDefault();

    let data = JSON.stringify({
      charter_type: `${isPassenger ? "passenger" : "cargo"}`,
      total_people: noOfPax,
      departing_from: depFrom,
      departure_date: depDate,
      departure_time: depTime,
      destination: destination,
      return_date: returnDate,
      return_time: returnTime,
      total_weight: totalWeight,
      total_volume: totalVol,
      largest_size: largestSize,
      dimensions: dimensions,
      commodity_details: commodityDetails,
      additional_information: addInfo,
      name: name,
      email: email,
      company: company,
      telephone: telephone,
      country: country,
      mobile: mobile,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${API_URL}charter-enquiry/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${window.btoa("frontend:272G:S4QvS!eSgJ")}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        ToastNotification.fire({
          icon: "success",
          title: "Successful!",
        });
      })
      .catch((error) => {
        console.log(error);
        ToastNotification.fire({
          icon: "warning",
          title: "Failed!",
        });
      });
    firstStep();
  };

  const Previous = (event) => {
    event.preventDefault();
    prevStep();
  };

  const pasConfirm = [
    {
      name: "No. of Passengers *",
      value: noOfPax,
    },
    {
      name: "Departing From *",
      value: depFrom,
    },
    {
      name: "Departure Date *",
      value: depDate,
    },
    {
      name: "Departure Time",
      value: depTime,
    },
    {
      name: "Destination *",
      value: destination,
    },
    {
      name: "Return Date",
      value: returnDate,
    },
    {
      name: "Return Time",
      value: returnTime,
    },
    {
      name: "Name*",
      value: name,
    },
    {
      name: "Email*",
      value: email,
    },
    {
      name: "Company*",
      value: company,
    },
    {
      name: "Telephone*",
      value: telephone,
    },
    {
      name: "Country*",
      value: country,
    },
    {
      name: "Mobile",
      value: mobile,
    },
  ];

  const cargoConfirm = [
    {
      name: "Departing From *",
      value: depFrom,
    },
    {
      name: "Departure Date *",
      value: depDate,
    },
    {
      name: "Departure Time",
      value: depTime,
    },
    {
      name: "Destination *",
      value: destination,
    },
    {
      name: "Return Date",
      value: returnDate,
    },
    {
      name: "Return Time",
      value: returnTime,
    },
    {
      name: "Total weight (tons)",
      value: totalWeight,
    },
    {
      name: "Total volume (m3)",
      value: totalVol,
    },
    {
      name: "Weight of Largest Piece (kg)",
      value: largestSize,
    },
    {
      name: "Largest Dimensions (cm)",
      value: dimensions,
    },
    {
      name: "Name*",
      value: name,
    },
    {
      name: "Email*",
      value: email,
    },
    {
      name: "Company*",
      value: company,
    },
    {
      name: "Telephone*",
      value: telephone,
    },
    {
      name: "Country*",
      value: country,
    },
    {
      name: "Mobile",
      value: mobile,
    },
  ];
  return (
    <div className="my-10">
      <p className="text-white">
        <b className="text-2xl uppercase">Confirm?</b>
      </p>
      <hr className="h-[2px] my-4 bg-white" />
      <ul className="p-5 md:grid 2xl:grid-cols-3 xl:grid-cols-2 md:grid-cols-3 gap-x-6">
        {isPassenger ? (
          <>
            {pasConfirm.map((item, index) => {
              return (
                <li key={index} className="pb-5">
                  <p className="text-white text-[18px]">
                    <b>{item.name}:</b>
                  </p>
                  {item.value ? (
                    <p className="text-white text-[16px] font-[100]">
                      {item.name === "Departure Date *" ||
                      item.name === "Return Date" ? (
                        <>
                          {Intl.DateTimeFormat("en-GB").format(
                            new Date(item.value)
                          )}
                        </>
                      ) : (
                        <>{item.value}</>
                      )}
                    </p>
                  ) : (
                    <p className="text-white text-[16px] font-[100]">N/A</p>
                  )}
                </li>
              );
            })}
          </>
        ) : (
          <>
            {cargoConfirm.map((item, index) => {
              return (
                <li key={index} className="pb-5">
                  <p className="text-white text-[18px]">
                    <b>{item.name}:</b>
                  </p>
                  {item.value ? (
                    <p className="text-white text-[16px] font-[100]">
                      {item.name === "Departure Date *" ||
                      item.name === "Return Date" ? (
                        <>
                          {Intl.DateTimeFormat("en-GB").format(
                            new Date(item.value)
                          )}
                        </>
                      ) : (
                        <>{item.value}</>
                      )}
                    </p>
                  ) : (
                    <p className="text-white text-[16px] font-[100]">N/A</p>
                  )}
                </li>
              );
            })}

            <li className="pb-5 col-span-3 whitespace-pre-wrap">
              <p className="text-white text-[18px]">
                <b>Commodity Details:</b>
              </p>
              {commodityDetails ? (
                <p className="text-white text-[16px] font-[100]">
                  {commodityDetails}
                </p>
              ) : (
                <p className="text-white text-[16px] font-[100]">N/A</p>
              )}
            </li>
          </>
        )}
        <li className="pb-5 col-span-3 whitespace-pre-wrap">
          <p className="text-white text-[18px]">
            <b>Additional Information:</b>
          </p>
          {addInfo ? (
            <p className="text-white text-[16px] font-[100]">{addInfo}</p>
          ) : (
            <p className="text-white text-[16px] font-[100]">N/A</p>
          )}
        </li>
      </ul>
      <button className="mr-4" onClick={Previous} type="button">
        Back
      </button>
      <button onClick={Submit} type="submit">
        Submit
      </button>
    </div>
  );
};

export default Confirmation;
