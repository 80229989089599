import React, { useEffect, useState } from "react";
import useFetch from "../utils/useFetch";
// import Footer from "../components/general/footer";
import { API_URL } from "../constants";
import PricesCard from "../components/prices/pricesCard";
import FilterPrices from "../components/prices/filterPrices";

const Prices = () => {
  const [pricesData, setPricesData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [showFilters, setShowFilters] = useState(false);

  // feth data from api
  const { data: passengers, error: passengerError } = useFetch(
    `${API_URL}passenger-charter-rates/`
  );
  const { data: cargo, error: cargoError } = useFetch(
    `${API_URL}cargo-charter-rates/`
  );

  //store passenger and cargo data in one
  useEffect(() => {
    if (passengers && cargo) {
      setPricesData([...passengers, ...cargo]);
      setFilteredData([...passengers, ...cargo]);
    }
  }, [passengers, cargo]);

  const handleFilter = () => {
    setShowFilters(!showFilters);
  };
  return (
    <div className="lg:pt-[107px]">
      <div className="lg:flex lg:gap-4">
        {/* filters */}
        <div className="bg-[#314969] xl:w-[15vw] lg:w-[23vw] w-full lg:fixed lg:left-0 lg:top-24 lg:h-[90vh] p-6 lg:pt-10 pt-32 text-[#EAF1FA] overflow-y-scroll scrollbar-thin scrollbar-thumb-[#C3D9F6] scrollbar-track-[#314969]">
          <h5 className="xl:text-2xl text-xl pb-3 md:pt-0 pt-2 font-semibold inline-block">
            Filters
          </h5>
          <button
            className="float-right md:hidden text-sm"
            onClick={handleFilter}
          >
            {showFilters ? "Hide Filters" : "View Filters"}
          </button>
          <div className={`${showFilters ? "block" : "hidden"} md:block`}>
            <FilterPrices
              setFilteredData={setFilteredData}
              pricesData={pricesData}
            />
          </div>
        </div>
        <div className=" xl:w-[15vw] lg:w-[25vw] -z-10" />

        {/* content */}
        <div className="max-w-screen-2xl min-w-[75vw] mx-auto px-5 min-h-screen ">
          <h1 className="md:text-6xl text-3xl text-[#3C6195] font-bold lg:mt-10 mt-16 mb-5">
            Indicative Prices
          </h1>
          <p>
            The provided prices are indicative and guidance for the chosen
            sector and airlines.
          </p>

          {/* Prices */}
          {filteredData.length !== 0 ? (
            <PricesCard filteredData={filteredData} />
          ) : passengerError ? (
            <p className="text-center">{passengerError}</p>
          ) : cargoError ? (
            <p className="text-center">{cargoError}</p>
          ) : (
            <h1 className="text-center text-2xl mt-10 py-3 bg-[#C3D9F6] text-[#5a5a5a] rounded-lg">
              That data is not available.
            </h1>
          )}
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default Prices;
