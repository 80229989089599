import React from "react";

import { HiOutlineArrowSmRight } from "react-icons/hi";
import { HiMiniArrowsRightLeft } from "react-icons/hi2";
const PricesCard = ({ filteredData }) => {
  // console.log(filteredData);
  return (
    <div className="grid xl:grid-cols-2 lg:grid-cols-1 md:grid-cols-2 grid-cols-1 gap-4 py-10">
      {filteredData.map((item, index) => (
        <div
          key={index}
          className="break-inside-avoid bg-[#C3D9F6] rounded-lg p-8 relative xl:w-[36.5vw]"
        >
          {/* top part: Airline Info */}
          <div className="lg:flex gap-4 pb-5">
            {/* logo */}
            <div className="w-40 h-40 p-5 bg-slate-50 rounded-full flex items-center justify-center lg:mx-0 mx-auto">
              {item.airline.linked_partner.logo ? (
                <img
                  src={item.airline.linked_partner.logo}
                  alt={`${item.airline.name} logo`}
                />
              ) : (
                <p className="text-center">{item.airline.name}</p>
              )}
            </div>
            {/* info */}
            <div className="lg:pl-5 pt-2 lg:text-left text-center">
              <h5 className="xl:text-2xl text-xl pb-3 font-semibold text-[#5a5a5a]">
                {item.airline.name}
              </h5>
              <p className="pb-2">
                <b>Type:</b> {item.aircraft_type.code}:&nbsp;
                {item.aircraft_type.name} ({item.charter_type})
              </p>
              <p className="pb-2">
                <b>Rate per block hour (USD): </b>
                <span className="text-nowrap">
                  {item.rate_per_hour ? `${item.rate_per_hour} ++` : "X"}
                </span>
              </p>
              <p className="pb-2">
                <b>Block hours: </b>
                {item.minimum_hours ? item.minimum_hours : "X"}
              </p>
            </div>
          </div>
          {/* middle part: Sector + seats etc */}
          <div className="py-5 border-y-[1px] border-[#9E9E9E] ">
            <h5 className="xl:text-2xl text-xl pb-3 text-[#5a5a5a]">
              <b>Sector: </b>
              {item.charter_sector.name}
            </h5>
            <p className="pb-2">
              <b>Airport Code: </b>
              {item.charter_sector.airport_codes}
            </p>
            {item.charter_type === "Passenger" ? (
              <>
                {item.passenger_seats ? (
                  <div className="lg:flex flex-wrap gap-x-7">
                    {item.passenger_seats.map((item, index) => (
                      <>
                        {item.seat_count ? (
                          <p className="pb-2">
                            <b>{item.seat_class}: </b>
                            {item.seat_count}
                          </p>
                        ) : (
                          ""
                        )}
                      </>
                    ))}
                  </div>
                ) : (
                  ""
                )}
                <p className="pb-2">
                  <b>Total Seats: </b>
                  {item.total_passenger_seats}
                </p>
              </>
            ) : item.charter_type === "Cargo" ? (
              <div>
                {item.max_allowed_weight ? (
                  <p className="pb-2">
                    <b>Max Weight per piece: </b>
                    {item.max_allowed_weight}
                  </p>
                ) : (
                  ""
                )}
                <p className="pb-2">
                  <b>Cargo Capacity: </b>
                  {item.cargo_capacity} (tons)
                </p>

                {item.cargo_dimensions.length ? (
                  <div className="pb-2">
                    <p className="font-bold">Cargo Max Dimensions: </p>
                    <ul className="list-disc pl-6 lg:grid xl:grid-cols-3 grid-cols-2 gap-4">
                      {item.cargo_dimensions.map((dimension, index) => (
                        <li key={index}>
                          <p>
                            {dimension.length} X {dimension.width} X
                            {dimension.height}&nbsp;
                            {dimension.pieces
                              ? `( ${dimension.pieces} pcs)`
                              : ""}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>
          {/* bottom part: Prices */}
          {/* <div className="py-5 lg:flex justify-between border-b-[1px] border-[#9E9E9E] "> */}
          <div className="py-5  border-b-[1px] border-[#9E9E9E] ">
            <h5 className="text-center text-black font-['Inter']">
              <HiOutlineArrowSmRight className="xl:text-2xl text-xl inline" />
              &nbsp;<span className="text-lg">(1-Way): USD </span>
              <b className="xl:text-2xl text-xl">
                {Intl.NumberFormat("en-AU").format(
                  item.charter_rates.rate_for_one_way
                )}
                .00
              </b>
              &nbsp;++
            </h5>
            <h5 className="text-center text-black font-['Inter']">
              <HiMiniArrowsRightLeft className="xl:text-2xl text-xl inline" />
              &nbsp;<span className="text-lg">(2-Way): USD </span>
              <b className="xl:text-2xl text-xl">
                {Intl.NumberFormat("en-AU").format(
                  item.charter_rates.rate_for_return
                )}
                .00
              </b>
              &nbsp;++
            </h5>
          </div>
          {/* final: validity */}
          <div className="pt-5">
            <p className="text-[#6d6d6d] text-[18px] text-center">
              Valid till:{" "}
              {Intl.DateTimeFormat("en-GB").format(
                new Date(`${item.rate_validity.rate_validity_end}T12:00:00Z`)
              )}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PricesCard;
