import React from "react";

const FilterPricesRadio = ({ filtering, handleTypeFilter }) => {
  return (
    <div>
      {filtering.map((type, index) => (
        <div className="flex items-center mb-2" key={index}>
          <input
            id={type}
            type="radio"
            onChange={handleTypeFilter}
            value={type}
            name="filter"
            className="w-4 h-4 text-gray-300 bg-gray-100 border-gray-300 focus:ring-gray-300"
          />
          <label
            htmlFor={type}
            className="ms-2 text-sm font-medium text-gray-300"
          >
            {type}
          </label>
        </div>
      ))}
    </div>
  );
};

export default FilterPricesRadio;
