import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";

const Whatsapp = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  const [showButton, setShowButton] = useState(true);

  const toggleButton = () => {
    setShowButton(!showButton);
  };

  return (
    <>
      {showButton && (
        <button
          className=" fixed bottom-28 right-20 z-50 rounded-full min-w-32 shadow-lg bg-white border border-slate-300 "
        >
          <div
            onClick={toggleChat}
            target="_blank"
            className=" text-xs text-black"
          >
            Chat with us
          </div>
          <IoMdClose
            onClick={toggleButton}
            className="fixed bottom-[120px] right-12 z-50 text-black text-xl bg-white border border-slate-300 rounded-full p-[1px]"
          />
        </button>
      )}

      <div
        onClick={toggleChat}
        className="fixed bottom-10 right-10 z-50 bg-slate-50 p-3 rounded-full drop-shadow-lg "
      >
        <img
          src={
            isChatOpen
              ? require("../../assets/images/down.png")
              : require("../../assets/images/whatsapp.png")
          }
          alt="whatsapp logo"
          className="w-10 drop-shadow-lg"
        />
      </div>

      <div
        className={`${
          isChatOpen ? "visible opacity-100" : "invisible opacity-0"
        } fixed bottom-28 right-10 bg-white p-8 rounded-md shadow-lg space-y-3 font-bold transition-all delay-75 ease-in-out z-50`}
      >
        <IoMdClose
          onClick={toggleChat}
          className="float-right z-50 text-black text-xl bg-white border border-slate-300 rounded-full p-[1px]"
        />
        <img
          className="w-32 block mx-auto"
          src={require("../../assets/images/logo.png")}
          alt="logo"
        />
        <p className="text-lg font-medium">
          Hello! <br />
          How can we help you?
        </p>
        <a
          href="https://api.whatsapp.com/send?phone=6590042659"
          target="_blank"
          rel="noreferrer"
          className="block text-lg text-white rounded-full shadow-sm min-w-[240px] p-2 text-center uppercase"
          style={{ backgroundColor: "#547196" }}
        >
          Chat with us
        </a>
      </div>
    </>
  );
};

export default Whatsapp;
