import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { API_URL } from "../../constants.js";
import useFetch from "../../utils/useFetch.js";

const Carousel = ({ partnersRef }) => {
  // fetching data
  const { data: partners, error: partners_error } = useFetch(
    `${API_URL}partners/`
  );

  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={`${className} rounded-full text-center content-center `}
        style={{ display: "grid" }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={`${className} rounded-full text-center content-center `}
        style={{ display: "grid" }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    centerMode: true,
    className: "center",
    centerPadding: "60px",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    slidesToScroll: 1,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="md:pt-10 relative -bottom-10" ref={partnersRef}>
      <h1 className="md:text-6xl text-3xl text-[#3C6195] font-bold text-center my-10">
        We partner with the best
      </h1>
      <div className="partners-carousel">
        {partners ? (
          <Slider {...settings}>
            {partners.map((item, index) => (
              <div
                key={index}
                className="relative max-w-[95%] md:ml-4 ml-2 mr-6 mt-12 rounded-xl md:min-h-[38vh] min-h-[45vh] md:p-10 px-5 pt-8 pb-10 transition-all duration-700 delay-100 ease-in-out"
              >
                {/* BG plane image */}
                <img
                  className="absolute top-0 left-0 rounded-xl w-full h-full object-cover object-top -z-10"
                  src={
                    item.plane
                      ? item.plane
                      : `${require("../../assets/images/plane-flying.png")}`
                  }
                  alt={item.name + " plane"}
                />
                {/* logo image */}
                {item.logo ? (
                  <img
                    className="logo brightness-[100] grayscale mx-auto md:h-20 h-14 pb-5 md:pt-3 object-contain transition-all duration-700 delay-300 ease-in-out"
                    src={item.logo}
                    alt={item.name}
                  />
                ) : (
                  <h1 className="text-center lg:text-3xl text-2xl pb-5 text-white transition-all duration-700 delay-300 ease-in-out">
                    {item.name}
                  </h1>
                )}
                <p className="partner-desc opacity-0 text-[#444] text-center md:text-[15px] text-[13px] transition-all delay-300 ease-in-out">
                  {item.description}
                </p>
              </div>
            ))}
          </Slider>
        ) : partners_error ? (
          <p className="text-center">{partners_error}</p>
        ) : (
          <p className="text-center">loading</p>
        )}
      </div>
    </div>
  );
};

export default Carousel;
