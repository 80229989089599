import { Field } from "formik";
import { React, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";

const CargoInformation = ({ cargo, index, remove }) => {
  const [cargoPicked, setCargoPicked] = useState(0);

  const handleChangeCargo = (event) => {
    setCargoPicked(`${index}${event.target.value}cargo`);
  };

  return (
    <div className="relative pt-12" style={{ color: "#334A6D" }} key={index}>
      <div className=" text-2xl pb-2 font-bold mb-8 border-b-2 border-[#334A6D]">
        Cargo Charter Information - {index + 1}
        <AiOutlineDelete
          className="border rounded-md text-3xl p-1 absolute top-5 right-0 text-red-600 border-red-600 hover:text-white hover:bg-red-600"
          onClick={() => remove(index)}
        />
      </div>

      <div className="grid md:grid-cols-3 md:gap-6">
        <div className="relative z-0 w-full mb-5 group">
          <Field
            name={`cargoCharter.${index}.charterSector`}
            type="text"
            className="block py-2.5 w-full bg-transparent border-b focus:outline-none peer"
            style={{ borderBlockColor: "#334A6D" }}
            placeholder=" "
            required
          />
          <label className="absolute text-md duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 peer-placeholder-shown:scale-100">
            CHARTER SECTOR *
          </label>
        </div>
        <div className="relative z-0 w-full mb-5 group">
          <Field
            name={`cargoCharter.${index}.aircraftType`}
            type="text"
            className="block py-2.5 w-full bg-transparent border-b border-[#334A6D] focus:outline-none peer"
            style={{ borderBlockColor: "#334A6D" }}
            placeholder=" "
            required
          />
          <label className="absolute text-md duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 peer-placeholder-shown:scale-100">
            AIRCRAFT TYPE *
          </label>
        </div>
        <div className="relative z-0 w-full mb-5 group">
          <Field
            name={`cargoCharter.${index}.aircraftNumber`}
            type="number"
            min="0"
            className="block py-2.5 w-full bg-transparent border-b border-[#334A6D] focus:outline-none peer"
            style={{ borderBlockColor: "#334A6D" }}
            placeholder=" "
            required
          />
          <label className="absolute text-md duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 peer-placeholder-shown:scale-100">
            NO. OF AIRCRAFT(s) *
          </label>
        </div>
      </div>

      <div className="grid lg:grid-cols-5 md:grid-cols-3 md:gap-6">
        <div className="relative z-0 w-full mb-5 group">
          <Field
            name={`cargoCharter.${index}.cargoCapacity`}
            type="number"
            min="0"
            className="block py-2.5 w-full bg-transparent border-b border-[#334A6D] focus:outline-none peer"
            style={{ borderBlockColor: "#334A6D" }}
            placeholder=" "
            required
          />
          <label className="absolute text-md duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 peer-placeholder-shown:scale-100">
            CARGO CAPACITY *
          </label>
        </div>
        <div className="relative z-0 w-full mb-5 group">
          <Field
            name={`cargoCharter.${index}.cargo_dimensions.0.maxLength`}
            type="number"
            min="0"
            className="block py-2.5 w-full bg-transparent border-b border-[#334A6D] focus:outline-none peer"
            style={{ borderBlockColor: "#334A6D" }}
            placeholder=" "
            required
          />
          <label className="absolute text-md duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 peer-placeholder-shown:scale-100">
            MAX DIMENSION LENGTH *
          </label>
        </div>
        <div className="relative z-0 w-full mb-5 group">
          <Field
            name={`cargoCharter.${index}.cargo_dimensions.0.maxWidth`}
            type="number"
            min="0"
            className="block py-2.5 w-full bg-transparent border-b border-[#334A6D] focus:outline-none peer"
            style={{ borderBlockColor: "#334A6D" }}
            placeholder=" "
            required
          />
          <label className="absolute text-md duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 peer-placeholder-shown:scale-100">
            MAX DIMENSION WIDTH *
          </label>
        </div>
        <div className="relative z-0 w-full mb-5 group">
          <Field
            name={`cargoCharter.${index}.cargo_dimensions.0.maxHeight`}
            type="number"
            min="0"
            className="block py-2.5 w-full bg-transparent border-b border-[#334A6D] focus:outline-none peer"
            style={{ borderBlockColor: "#334A6D" }}
            placeholder=" "
            required
          />
          <label className="absolute text-md duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 peer-placeholder-shown:scale-100">
            MAX DIMENSION HEIGHT *
          </label>
        </div>
        <div className="relative z-0 w-full mb-5 group">
          <Field
            name={`cargoCharter.${index}.cargo_dimensions.0.pieces`}
            type="number"
            min="0"
            className="block py-2.5 w-full bg-transparent border-b border-[#334A6D] focus:outline-none peer"
            style={{ borderBlockColor: "#334A6D" }}
            placeholder=" "
            required
          />
          <label className="absolute text-md duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 peer-placeholder-shown:scale-100">
            PIECES *
          </label>
        </div>
      </div>

      <div className="lg:grid lg:grid-cols-3 md:gap-6 min-h-16">
        <div className="md:flex gap-6 md:min-h-16 min-h-24">
          <div>Enter Rate:</div>
          <div className="grid grid-cols-2 gap-4">
            <label
              htmlFor={`cargo-block-hour${index}`}
              className="h-10 flex items-center ps-4 px-6 border border-[#334A6D] rounded"
            >
              <Field
                id={`cargo-block-hour${index}`}
                type="radio"
                value="1"
                name={`cargoRate${index}`}
                className="w-4 h-4 bg-gray-100 border-gray-300 focus:ring-[#334A6D]"
                onClick={handleChangeCargo}
                required
              />
              <label
                htmlFor={`cargo-block-hour${index}`}
                className=" py-2 ps-2 text-md "
              >
                Block Hour
              </label>
            </label>
            <label
              htmlFor={`cargo-sector${index}`}
              className="h-10 flex items-center ps-4 px-6 border border-[#334A6D] rounded"
            >
              <Field
                id={`cargo-sector${index}`}
                type="radio"
                value="2"
                name={`cargoRate${index}`}
                className="w-4 h-4 bg-gray-100 border-gray-300 focus:ring-[#334A6D]"
                onClick={handleChangeCargo}
              />
              <label
                htmlFor={`cargo-sector${index}`}
                className=" py-2 ps-2 text-md "
              >
                Sector
              </label>
            </label>
          </div>
        </div>

        {cargoPicked === `${index}1cargo` ? (
          <div className="col-span-2 grid md:grid-cols-2 md:gap-6">
            <div className="relative z-0 w-full mb-5 group">
              <Field
                name={`cargoCharter.${index}.ratePerHour`}
                type="number"
                min="0"
                className="block py-2.5 w-full bg-transparent border-b border-[#334A6D] focus:outline-none peer"
                style={{ borderBlockColor: "#334A6D" }}
                placeholder=" "
                required
              />
              <label className="absolute text-md duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 peer-placeholder-shown:scale-100">
                RATE PER HOUR *
              </label>
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <Field
                name={`cargoCharter.${index}.minHours`}
                type="number"
                min="0"
                className="block py-2.5 w-full bg-transparent border-b border-[#334A6D] focus:outline-none peer"
                style={{ borderBlockColor: "#334A6D" }}
                placeholder=" "
                required
              />
              <label className="absolute text-md duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 peer-placeholder-shown:scale-100">
                MINIMUM HOURS *
              </label>
            </div>
          </div>
        ) : cargoPicked === `${index}2cargo` ? (
          <div className="col-span-2 grid md:grid-cols-2 md:gap-6">
            <div className="relative z-0 w-full mb-5 group">
              <Field
                name={`cargoCharter.${index}.rateOneWay`}
                type="number"
                min="0"
                className="block py-2.5 w-full bg-transparent border-b border-[#334A6D] focus:outline-none peer"
                style={{ borderBlockColor: "#334A6D" }}
                placeholder=" "
                required
              />
              <label className="absolute text-md duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 peer-placeholder-shown:scale-100 uppercase">
                Rate for one way *
              </label>
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <Field
                name={`cargoCharter.${index}.rateReturn`}
                type="number"
                min="0"
                className="block py-2.5 w-full bg-transparent border-b border-[#334A6D] focus:outline-none peer"
                style={{ borderBlockColor: "#334A6D" }}
                placeholder=" "
                required
              />
              <label className="absolute text-md duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 peer-placeholder-shown:scale-100 uppercase">
                Rate for return *
              </label>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="grid md:grid-cols-2 md:gap-6">
        <div className="relative z-0 w-full mb-5 group">
          <Field
            name={`cargoCharter.${index}.rateValidityStart`}
            type="date"
            className="block py-2.5 w-full bg-transparent border-b border-[#334A6D] focus:outline-none peer"
            style={{ borderBlockColor: "#334A6D" }}
            placeholder=" "
            required
          />
          <label className="absolute text-md duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 peer-placeholder-shown:scale-100">
            RATE VALIDITY START *
          </label>
        </div>
        <div className="relative z-0 w-full mb-5 group">
          <Field
            name={`cargoCharter.${index}.rateValidityEnd`}
            type="date"
            className="block py-2.5 w-full bg-transparent border-b border-[#334A6D] focus:outline-none peer"
            style={{ borderBlockColor: "#334A6D" }}
            placeholder=" "
            required
          />
          <label className="absolute text-md duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 peer-placeholder-shown:scale-100">
            RATE VALIDITY END *
          </label>
        </div>
      </div>
    </div>
  );
};

export default CargoInformation;
