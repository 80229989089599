import React from "react";
import { API_URL } from "../../constants";
import useFetch from "../../utils/useFetch";
import Banner from "./banner";

const Choose = ({ serviceRef }) => {
  // fetching data
  const { data: service, error: service_error } = useFetch(
    `${API_URL}services/`
  );
  const { data: statistics, error: statistics_error } = useFetch(
    `${API_URL}statistics/`
  );

  return (
    <div ref={serviceRef}>
      <div className="text-center pb-10 px-5">
        <h1
          className="font-bold mb-6 pt-20 md:text-6xl text-4xl"
          style={{ color: "#3C6195" }}
        >
          Why choose us?
        </h1>
        <p>
          Luxury, Efficiency, Affordability: Elevate your journey with Chariot
          Charters today.
        </p>
      </div>

      {service ? (
        <div className="lg:grid lg:grid-cols-3 lg:gap-10 lg:px-32 px-5 place-content-center lg:space-y-0 space-y-8">
          {service.map((item, index) => (
            <div
              key={index}
              className="p-8 rounded-lg text-white md:mx-0 mx-auto "
              style={
                item.id === 2
                  ? { backgroundColor: "#547196 " }
                  : { backgroundColor: "#608ECC" }
              }
            >
              <div className="text-white mb-5 ">
                <img src={item.icon} alt="icon" />
              </div>

              <h5 className="mb-2 text-2xl font-bold ">{item.title}</h5>
              <p className="mb-3 text-white text-[16px]">{item.description}</p>
            </div>
          ))}
        </div>
      ) : service_error ? (
        <p className="text-center">{service_error}</p>
      ) : (
        <p className="text-center">loading</p>
      )}

      <Banner />

      {statistics ? (
        <div className="md:grid lg:grid-cols-4 md:grid-cols-2 md:gap-10 lg:px-32 px-5 place-content-center pt-20 md:space-y-0 space-y-8">
          {statistics.map((item, index) => (
            <div key={index}>
              <h1 className="font-bold text-4xl text-[#547196]">
                {item.number}+
              </h1>
              <p className="font-bold pt-3 pb-5 ">{item.title}</p>
              <p>{item.description}</p>
            </div>
          ))}
        </div>
      ) : statistics_error ? (
        <p className="text-center">{statistics_error}</p>
      ) : (
        <p className="text-center">loading</p>
      )}
    </div>
  );
};

export default Choose;
