import React from "react";
import HomePage from "../components/home/homePage";
import About from "../components/home/about";
import Book from "../components/home/book";
import Services from "../components/home/services";
import Partners from "../components/home/partners";
import Enquiry from "../components/enquiry/enquiry";
import Whatsapp from "../components/general/whatsapp";
import Footer from "../components/general/footer";

const Home = ({ serviceRef, aboutRef, partnersRef, enquiryRef }) => {
  return (
    <div className="max-w-[100vw]">
      <HomePage />
      <About aboutRef={aboutRef} />
      <Book enquiryRef={enquiryRef} />
      <Services serviceRef={serviceRef} />
      <Partners partnersRef={partnersRef} />
      <Enquiry enquiryRef={enquiryRef} />
      <Footer />
      <Whatsapp />
    </div>
  );
};

export default Home;
