import React from "react";
import bgImg from "../../assets/images/plane-flying.png";
import { API_URL } from "../../constants";
import useFetch from "../../utils/useFetch";

const HomePage = () => {
  // fetching data
  const { data: homePage, error } = useFetch(`${API_URL}home/`);

  return (
    <div
      className="bg-fixed bg-center bg-cover relative min-h-screen z-[-2]"
      style={{ backgroundImage: `url(${bgImg})`, backgroundColor: "#6da1e0" }}
    >
      <div className="relative md:top-[40vh] top-[40vh] ">
        {homePage ? (
          <div className="md:mx-20 mx-5">
            <h1 className="text-white lg:text-[7vw] md:text-[80px] text-5xl font-bold z-[-1]">
              Chariot Charters
            </h1>
            <p className="text-white lg:text-[1.78vw] md:text-[25px] z-[-1] lg:w-[55vw] ">
              {homePage[1].description}
            </p>
          </div>
        ) : error ? (
          <p className="text-center">{error}</p>
        ) : (
          <p className="text-center">loading</p>
        )}
      </div>
    </div>
  );
};

export default HomePage;
